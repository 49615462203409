.privacy-policy {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.privacy-policy h1 {
  margin-bottom: 20px;
}

.privacy-policy .content {
  line-height: 1.6;
}

.privacy-policy p {
  margin-bottom: 15px;
  opacity: 0.8;
}

nav {
  padding: 20px;
}

nav a {
  margin-right: 20px;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.privacy-policy h2 {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  opacity: 0.95;
}

.privacy-policy h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  opacity: 0.9;
}

.privacy-policy p {
  margin-bottom: 1rem;
  line-height: 1.7;
  opacity: 0.8;
}

.privacy-policy ul {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  opacity: 0.8;
}

.privacy-policy li {
  margin-bottom: 0.5rem;
}

.privacy-policy strong {
  opacity: 1;
  color: white;
} 